import { generateAuthHeader } from '#/api/utils';
import {
  transformPayorEnrollmentWorkflowByIdResponse,
  transformPayorEnrollmentWorkflowsDetails,
} from '#/data-transformers/payor-enrollment-workflows.transformer';
import {
  FetchPEWorkflowsQueryParams,
  TransformedFetchPEWorkflowsResponse,
  TransformedFetchPEWorkflowByIdResponse,
  PEWorkflowAssignee,
  BillingAndTax,
  ActivityLog,
  ActivityLogQueryParams,
  UpdatePayorEnrollmentWorkflowGroupPayload,
  PEMissingInformationResponse,
  PEWorkflowOutreachResponse,
  PEWorkflowActivityNotes,
  ActivityNoteQueryParams,
} from '#/types/provider-enrollment-workflows.types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { tagsWithId } from './tags/utils';

import {
  BulkResubmitPEWorkflowsRequest,
  BulkUploadResponse,
  EntityEnrollmentDetails,
  EntityLocationResponse,
  ResubmitPEWorkflowRequest,
} from '#/types/org-entity.types';

import {
  transformFetchAllWorkflowOutreachAttemptsResponse,
  transformFetchOrgEntityEnrollmentsByIdResponse,
} from '#/data-transformers';
import { OutreachAttemptPayload } from '#/types';

type PaginationQueryParams = {
  limit?: number;
  offset?: number;
  search?: string;
};

export const payorEnrollmentWorkflowsApi = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchPayorEnrollmentWorkflows: builder.query<
      TransformedFetchPEWorkflowsResponse,
      FetchPEWorkflowsQueryParams
    >({
      query: (params) => {
        return {
          url: '/payor-enrollment-workflows',
          method: 'get',
          params,
        };
      },
      transformResponse: transformPayorEnrollmentWorkflowsDetails,
      providesTags: [TAGS_MAP.PE_WORKFLOWS],
    }),
    fetchPayorEnrollmentWorkflowMissingInformation: builder.query<
      PEMissingInformationResponse,
      string
    >({
      query: (id) => {
        return {
          url: `/payor-enrollment-workflows/${id}/missing-information`,
          method: 'get',
        };
      },
      providesTags: (_result, _error, id) =>
        tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
    }),
    fetcPEWorkflowSSNDetails: builder.query<{ ssn: string }, string>({
      query: (id) => {
        let url = `/payor-enrollment-workflows/${id}/provider-ssn`;
        return { url, method: 'get' };
      },
    }),
    fetchPayorEnrollmentWorkflow: builder.query<
      TransformedFetchPEWorkflowByIdResponse,
      string
    >({
      query: (workflowId) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}`,
          method: 'get',
        };
      },
      transformResponse: transformPayorEnrollmentWorkflowByIdResponse,
      providesTags: (_result, _error, id) =>
        tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
    }),
    updateProviderPayorEnrollmentWorkflow: builder.mutation<
      TransformedFetchPEWorkflowByIdResponse,
      { workflowId: string; data: { [key: string]: any } }
    >({
      query: ({ workflowId, data }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_WORKFLOW_MISSING_INFO,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    createNewPayorEnrollmentWorkflow: builder.mutation<unknown, unknown>({
      query: (data) => {
        return {
          url: '/payor-enrollment-workflows',
          method: 'post',
          data,
        };
      },
      invalidatesTags: () => [TAGS_MAP.PE_WORKFLOWS],
    }),
    validateNewPayorEnrollmentWorkflow: builder.mutation<unknown, unknown>({
      query: (data) => {
        return {
          url: '/payor-enrollment-workflows/validate',
          method: 'post',
          data,
        };
      },
    }),
    fetchPEWorkflowAssigneesList: builder.query<
      Array<PEWorkflowAssignee>,
      void
    >({
      query: () => ({
        url: '/payor-enrollment-workflows/assignees',
        method: 'GET',
        headers: generateAuthHeader(),
      }),
      providesTags: [TAGS_MAP.PE_WORKFLOWS_ASSIGNEES],
    }),
    bulkAssignPEWorkflowMutation: builder.mutation<
      unknown,
      {
        payload: {
          analysts: string[];
          workflowIds: string[];
        };
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/payor-enrollment-workflows/bulk-assign`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, _arg) => {
        return [TAGS_MAP.PE_WORKFLOWS, TAGS_MAP.PE_WORKFLOWS_ASSIGNEES];
      },
    }),
    updatePayorEnrollmentWorkflowGroup: builder.mutation<
      unknown,
      { workflowId: string; payload: UpdatePayorEnrollmentWorkflowGroupPayload }
    >({
      query: ({ workflowId, payload }) => ({
        url: `/payor-enrollment-workflows/${workflowId}/group`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_WORKFLOW_MISSING_INFO,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    updatePayorEnrollmentWorkflowContractSignatoryGroup: builder.mutation<
      unknown,
      { workflowId: string; payload: UpdatePayorEnrollmentWorkflowGroupPayload }
    >({
      query: ({ workflowId, payload }) => ({
        url: `/payor-enrollment-workflows/${workflowId}/contract-signatory`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    updateBillingAndTaxPEWorkflow: builder.mutation<
      unknown,
      {
        id: string;
        payload: {
          billingAndTax: BillingAndTax;
        };
      }
    >({
      query: ({ id, payload }) => {
        return {
          url: `/payor-enrollment-workflows/${id}/group`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: (_result, _error, { id }) => {
        return [
          ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
          TAGS_MAP.PE_WORKFLOWS,
        ];
      },
    }),
    fetchActivityLogs: builder.query<
      ActivityLog[],
      {
        workflowId: string;
        queryParams: ActivityLogQueryParams;
      }
    >({
      query: ({ workflowId, queryParams }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/activity-logs`,
          method: 'get',
          params: queryParams,
        };
      },
      providesTags: [TAGS_MAP.PE_WORKFLOW_ACTIVITY_LOG],
    }),
    updatePEWorkflowStatus: builder.mutation<
      unknown,
      {
        workflowId: string;
        step: string;
        metadata?: Record<string, string>;
        contentTypeHeaderIsFormData?: boolean;
        formData?: FormData;
      }
    >({
      query: ({
        workflowId,
        step,
        metadata,
        contentTypeHeaderIsFormData,
        formData,
      }) => {
        let headers = {};
        let data = {};
        if (contentTypeHeaderIsFormData && formData) {
          headers = {
            'Content-Type': 'multipart/form-data',
          };
          data = formData;
        } else if (metadata) {
          data = {
            ...metadata,
          };
        }
        return {
          url: `/payor-enrollment-workflows/${workflowId}/steps/${step}`,
          method: 'put',
          headers,
          data,
        };
      },
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
      ],
    }),

    updateContractDocuments: builder.mutation<
      unknown,
      {
        workflowId: string;
        contractFile: File;
        providerWorkflowIds: string[];
      }
    >({
      query: ({ workflowId, contractFile, providerWorkflowIds }) => {
        const formData = new FormData();
        formData.append('contractFile', contractFile);
        providerWorkflowIds.forEach((id) =>
          formData.append('providerWorkflowIds[]', id),
        );

        return {
          url: `/payor-enrollment-workflows/${workflowId}/upload-contract`,
          method: 'POST',
          body: formData,
          headers: {
            ...generateAuthHeader(),
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_result, _error, { workflowId }) => [
        { type: TAGS_MAP.PE_WORKFLOW, id: workflowId },
      ],
    }),

    updateNextActivityDate: builder.mutation<
      unknown,
      {
        workflowId: string;
        nextActivityDate: string;
        notes: string;
      }
    >({
      query: ({ workflowId, nextActivityDate, notes }) => ({
        url: `/payor-enrollment-workflows/${workflowId}/next-activity-date`,
        method: 'PATCH',
        body: {
          nextActivityDate,
          notes,
        },
      }),
      invalidatesTags: (_result, _error, { workflowId }) => [
        { type: TAGS_MAP.PE_WORKFLOW, id: workflowId },
      ],
    }),
    fetchEnrollmentsById: builder.query<
      EntityEnrollmentDetails,
      { workflowId: string } & PaginationQueryParams
    >({
      query: ({ workflowId, ...params }) => ({
        url: `/payor-enrollment-workflows/${workflowId}/enrollments`,
        method: 'get',
        params,
      }),
      transformResponse: transformFetchOrgEntityEnrollmentsByIdResponse,
      providesTags: () => [TAGS_MAP.ORG_ENTITY_ENROLLMENTS],
    }),
    exportPayorEnrollmentCsv: builder.mutation<
      unknown,
      {
        formData?: any;
      }
    >({
      query: ({ formData }) => {
        return {
          url: '/payor-enrollment-workflows/export-workflow',
          method: 'GET',
          params: formData,
          headers: generateAuthHeader(),
        };
      },
    }),
    markOutreachCommAsRead: builder.mutation<
      { id: string },
      { workflowId: string; outreachId: string }
    >({
      query: ({ workflowId, outreachId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/outreach/${outreachId}/mark-as-read`,
          method: 'POST',
        };
      },
    }),
    fetchAllPEWorkflowOutreachAttempts: builder.query<
      PEWorkflowOutreachResponse,
      { workflowId: string }
    >({
      query: ({ workflowId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/outreach`,
          method: 'GET',
        };
      },
      transformResponse: transformFetchAllWorkflowOutreachAttemptsResponse,
      providesTags: [TAGS_MAP.PE_WORKFLOW_OUTREACH_ATTEMPTS],
    }),
    createPEWorkflowOutreachAttempt: builder.mutation<
      { id: string },
      { workflowId: string; payload: OutreachAttemptPayload }
    >({
      query: ({ workflowId, payload }) => {
        if (
          payload?.method === 'email' &&
          payload?.attachments &&
          payload.attachments.length > 0
        ) {
          const formData = new FormData();

          // Append fields to FormData
          formData.append('method', 'email');
          if (payload.reason && payload.reason.length > 0) {
            payload.reason.forEach((reasonItem, index) => {
              formData.append(`reason[${index}]`, reasonItem);
            });
          }
          if (payload.to && payload.to.length > 0) {
            payload.to.forEach((toItem, index) => {
              formData.append(`to[${index}]`, toItem);
            });
          }
          if (payload.subject) formData.append('subject', payload.subject);
          if (payload.body) formData.append('body', payload.body);
          if (payload.isEmailSaveOnly)
            formData.append(
              'isEmailSaveOnly',
              payload.isEmailSaveOnly.toString(),
            );
          if (payload.attachments && payload.attachments.length > 0) {
            payload.attachments.forEach((file) => {
              formData.append('files', file);
            });
          }

          return {
            url: `/payor-enrollment-workflows/${workflowId}/outreach`,
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        } else {
          return {
            url: `/payor-enrollment-workflows/${workflowId}/outreach`,
            method: 'POST',
            body: payload,
          };
        }
      },
      invalidatesTags: [TAGS_MAP.PE_WORKFLOW_OUTREACH_ATTEMPTS],
    }),

    deletePEWorkflowOutreachAttempt: builder.mutation<
      { id: string },
      { workflowId: string; outreachId: string }
    >({
      query: ({ workflowId, outreachId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/outreach/${outreachId}`,
          method: 'DELETE',
        };
      },
    }),
    updatePEWorkflowOutreachAttempt: builder.mutation<
      { id: string },
      { workflowId: string; outreachId: string; payload: any }
    >({
      query: ({ workflowId, outreachId, payload }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/outreach/${outreachId}`,
          method: 'PATCH',
          body: payload,
        };
      },
      invalidatesTags: [TAGS_MAP.PE_WORKFLOW_OUTREACH_ATTEMPTS],
    }),
    createOtherInformationPayorEnrollmentWorkflow: builder.mutation<
      void,
      { workflowId: string; data: FormData }
    >({
      query: ({ workflowId, data }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/other-information`,
          method: 'POST',
          body: data,
          headers: {
            ...generateAuthHeader(),
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_WORKFLOW_MISSING_INFO,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    updateOtherInformationById: builder.mutation<
      void,
      { workflowId: string; otherId: string; data: FormData }
    >({
      query: ({ workflowId, data, otherId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/other-information/${otherId}`,
          method: 'PUT',
          body: data,
          headers: {
            ...generateAuthHeader(),
            'Content-Type': 'multipart/form-data',
          },
        };
      },
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_WORKFLOW_MISSING_INFO,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    deleteOtherInformationById: builder.mutation<
      void,
      { workflowId: string; otherId: string }
    >({
      query: ({ workflowId, otherId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/other-information/${otherId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, _error, { workflowId: id }) => [
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW, { id }),
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_WORKFLOW_MISSING_INFO,
        ...tagsWithId(TAGS_MAP.PE_WORKFLOW_MISSING_INFO, { id }),
      ],
    }),
    bulkActionsPEWorkflow: builder.mutation<unknown, {}>({
      query: (formData) => {
        return {
          url: `/payor-enrollment-workflows/bulk-actions`,
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
        };
      },
      invalidatesTags: () => [TAGS_MAP.PE_WORKFLOWS],
    }),
    validateBulkActionsPEWorkflow: builder.mutation<
      {
        errors: {
          workflowId: string;
          hasOpenTasks: boolean;
          errorMsg: string;
        }[];
      },
      { workflowIds: string[]; statusToMoveTo: string }
    >({
      query: (payload) => {
        return {
          url: `/payor-enrollment-workflows/bulk-actions/validate`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: () => [],
    }),
    fetchOrgEntityLoginPassword: builder.query<
      { data: string },
      { peWorkflowId: string; loginId: string }
    >({
      query: ({ peWorkflowId, loginId }) => {
        return {
          url: `/payor-enrollment-workflows/${peWorkflowId}/logins/${loginId}/password`,
          method: 'get',
        };
      },
    }),
    payorEnrollmentWorkflowsTemplateDownloadUrl: builder.query<string, void>({
      query: () => ({
        url: '/payor-enrollment-workflows/generate-template-download-url',
        method: 'get',
      }),
    }),
    uploadBulkPayor: builder.mutation<BulkUploadResponse, FormData>({
      query: (formData) => {
        return {
          url: '/payor-enrollment-workflows/bulk-import-pe-applications',
          method: 'post',
          data: formData,
        };
      },
      invalidatesTags: () => [TAGS_MAP.PE_WORKFLOWS],
    }),
    resubmitWorkflow: builder.mutation<void, ResubmitPEWorkflowRequest>({
      query: (data) => {
        return {
          url: `/payor-enrollment-workflows/${data.workflowId}/resubmit`,
          method: 'post',
          data: {
            note: data.note,
            reason: data.reason,
            providerWorkflowIds: data.providerWorkflowIds,
          },
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_FILTER_METRICS,
      ],
    }),
    bulkResubmitWorkflow: builder.mutation<
      void,
      BulkResubmitPEWorkflowsRequest
    >({
      query: (data) => {
        return {
          url: `/payor-enrollment-workflows/bulk-resubmit`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_FILTER_METRICS,
      ],
    }),
    closeWorkflowResubmissionMutation: builder.mutation<
      void,
      { workflowIds: string[]; note: string }
    >({
      query: (data) => {
        return {
          url: `/payor-enrollment-workflows/close-resubmissions`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.PE_WORKFLOWS,
        TAGS_MAP.PE_FILTER_METRICS,
      ],
    }),
    getFilterMetrics: builder.query<
      {
        open: number;
        rejected: number;
        groupRejected: number;
        individualRejected: number;
      },
      void
    >({
      query: () => {
        return {
          url: `/payor-enrollment-workflows/filter-metrics`,
          method: 'get',
        };
      },
      providesTags: [TAGS_MAP.PE_FILTER_METRICS],
    }),

    fetchEnrollmentsByProviderId: builder.query<EntityEnrollmentDetails, any>({
      query: ({ providerId, orgEntities, ...params }) => ({
        url: `/payor-enrollment-workflows/${providerId}/provider-enrollments`,
        method: 'get',

        params: {
          orgEntities,
        },
      }),
      transformResponse: transformFetchOrgEntityEnrollmentsByIdResponse,
      providesTags: () => [TAGS_MAP.ORG_ENTITY_ENROLLMENTS],
    }),
    fetchPayorEnrollmentWorkflowsActivityNotes: builder.query<
      PEWorkflowActivityNotes,
      {
        workflowId: string;
        queryParams: ActivityNoteQueryParams;
      }
    >({
      query: ({ workflowId, queryParams }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/activity-notes`,
          method: 'get',
          params: queryParams,
        };
      },
      providesTags: [TAGS_MAP.ACTIVITY_NOTES],
    }),
    fetchProviderEnrollmentForWorkflow: builder.query<
      {
        credentialingWorkflowTimeline: {
          lastCredentialedDate: Date;
          nextCredentialingDate: Date;
        };
      },
      {
        workflowId: string;
      }
    >({
      query: ({ workflowId }) => {
        return {
          url: `/payor-enrollment-workflows/${workflowId}/provider-enrollment`,
          method: 'get',
        };
      },
    }),
    fetchLocationsWithAdmittingPrivileges: builder.query<
      EntityLocationResponse[],
      void
    >({
      query: () => {
        return {
          url: `/payor-enrollment-workflows/locations-admitting-privileges`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useCreateNewPayorEnrollmentWorkflowMutation,
  useFetchPayorEnrollmentWorkflowsQuery,
  useBulkAssignPEWorkflowMutationMutation,
  useFetchPEWorkflowAssigneesListQuery,
  useLazyFetchPayorEnrollmentWorkflowsQuery,
  useFetchPayorEnrollmentWorkflowQuery,
  useLazyFetchPayorEnrollmentWorkflowQuery,
  useUpdateBillingAndTaxPEWorkflowMutation,
  useUpdateProviderPayorEnrollmentWorkflowMutation,
  useFetchActivityLogsQuery,
  useUpdatePEWorkflowStatusMutation,
  useUpdatePayorEnrollmentWorkflowGroupMutation,
  useUpdatePayorEnrollmentWorkflowContractSignatoryGroupMutation,
  useUpdateContractDocumentsMutation,
  useUpdateNextActivityDateMutation,
  useFetchEnrollmentsByIdQuery,
  useExportPayorEnrollmentCsvMutation,
  useFetchPayorEnrollmentWorkflowMissingInformationQuery,
  useValidateNewPayorEnrollmentWorkflowMutation,
  useMarkOutreachCommAsReadMutation,
  useCreatePEWorkflowOutreachAttemptMutation,
  useDeletePEWorkflowOutreachAttemptMutation,
  useFetchAllPEWorkflowOutreachAttemptsQuery,
  useUpdatePEWorkflowOutreachAttemptMutation,
  useCreateOtherInformationPayorEnrollmentWorkflowMutation,
  useUpdateOtherInformationByIdMutation,
  useDeleteOtherInformationByIdMutation,
  useBulkActionsPEWorkflowMutation,
  useLazyFetchOrgEntityLoginPasswordQuery,
  usePayorEnrollmentWorkflowsTemplateDownloadUrlQuery,
  useUploadBulkPayorMutation,
  useFetcPEWorkflowSSNDetailsQuery,
  useLazyFetcPEWorkflowSSNDetailsQuery,
  useResubmitWorkflowMutation,
  useBulkResubmitWorkflowMutation,
  useCloseWorkflowResubmissionMutationMutation,
  useGetFilterMetricsQuery,
  useLazyGetFilterMetricsQuery,
  useLazyFetchPayorEnrollmentWorkflowsActivityNotesQuery,
  useFetchPayorEnrollmentWorkflowsActivityNotesQuery,
  useFetchProviderEnrollmentForWorkflowQuery,
  useValidateBulkActionsPEWorkflowMutation,
  useFetchEnrollmentsByProviderIdQuery,
  useFetchLocationsWithAdmittingPrivilegesQuery,
} = payorEnrollmentWorkflowsApi;
