export const LICENSES_URLS_MAPPER: Record<string, Record<string, string>> = {
  AL: {
    ABA: 'https://mh.alabama.gov/wp-content/uploads/2024/10/Alabama-Behavior-Analyst-Licensure-Board-Roster-10.04.2024.pdf',
    ADC: '',
    CP: 'https://abpp.org/directory/',
    CSW: 'https://apps.socialwork.alabama.gov/shared/search.aspx',
    DO: 'https://abme.igovsolution.net/online/Lookups/Individual_Lookup.aspx',
    MD: 'https://abme.igovsolution.net/online/Lookups/Individual_Lookup.aspx',
    MFT: 'https://mft.alabama.gov/search.aspx?sm=d_c',
    PC: 'https://abec-search.kalmservices.net/',
  },
  AK: {
    ABA: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
    ADC: '',
    CP: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
    CSW: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
    DO: 'https://www.commerce.alaska.gov/cbp/main/search/professional',
    MD: 'https://www.commerce.alaska.gov/cbp/main/search/professional',
    MFT: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
    PC: 'https://www.commerce.alaska.gov/cbp/main/Search/Professional',
  },
  AR: {
    ABA: '',
    ADC: 'https://asacb.com/credential-verification/',
    CP: 'https://search.statesolutions.us/?A=APB&AID=RS&GUID=00293FDC7F754619A356AF20C2E4EDF9',
    CSW: 'https://www.roster.healthy.arkansas.gov/rosters/social-work-licensing-board/',
    DO: 'https://www.armedicalboard.org/Public.aspx',
    MD: 'https://www.armedicalboard.org/Public.aspx',
    MFT: 'https://abecmft.portalus.thentiacloud.net/webs/portal/register/#/',
    PC: 'https://abecmft.portalus.thentiacloud.net/webs/portal/register/#/',
  },
  AZ: {
    ABA: 'https://abpe.portalus.thentiacloud.net/webs/portal/register/#/profile/DEANNA/0/20/Behavior%2520Analyst/all/false/6376a2c305f657246c6e8d50',
    ADC: 'https://www.azbbhe.us/node/3',
    CP: 'https://abpe.portalus.thentiacloud.net/webs/portal/register/#/',
    CSW: 'https://www.azbbhe.us/node/3',
    DO: 'https://azdo.portalus.thentiacloud.net/webs/portal/register/#/',
    MD: 'https://azbomv7prod.glsuite.us/GLSuiteWeb/Clients/AZBOM/public/WebVerificationSearch.aspx?q=azmd&t=20240531100320',
    MFT: 'http://azbbhe.us/node/3',
    PC: 'https://azbbhe.us/node/3',
  },
  CA: {
    ABA: '',
    ADC: 'https://cadtpcounselors.org/verify-credentials/#verify/',
    CP: 'https://search.dca.ca.gov',
    CSW: 'https://search.dca.ca.gov',
    DO: 'https://search.dca.ca.gov',
    MD: 'https://search.dca.ca.gov',
    MFT: 'https://search.dca.ca.gov',
    PC: 'https://search.dca.ca.gov',
  },
  CO: {
    ABA: '',
    ADC: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    CP: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    CSW: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    DO: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    MD: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    MFT: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
    PC: 'https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx',
  },
  CT: {
    ABA: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    ADC: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    CP: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    CSW: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    DO: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    MD: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    MFT: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
    PC: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
  },
  DC: {
    ABA: '',
    ADC: 'https://dohenterprise.my.site.com/ver/s/',
    CP: 'https://dohenterprise.my.site.com/ver/s/',
    CSW: 'https://dohenterprise.my.site.com/ver/s/',
    DO: 'https://dohenterprise.my.site.com/ver/s/',
    MD: 'https://dohenterprise.my.site.com/ver/s/',
    MFT: 'https://dohenterprise.my.site.com/ver/s/',
    PC: 'https://dohenterprise.my.site.com/ver/s/',
  },
  DE: {
    ABA: '',
    ADC: 'https://delpros.delaware.gov/OH_VerifyLicense',
    CP: 'https://delpros.delaware.gov/OH_VerifyLicense',
    CSW: 'https://delpros.delaware.gov/OH_VerifyLicense',
    DO: 'https://delpros.delaware.gov/OH_VerifyLicense',
    MD: 'https://delpros.delaware.gov/OH_VerifyLicense',
    MFT: 'https://delpros.delaware.gov/OH_VerifyLicense',
    PC: 'https://delpros.delaware.gov/OH_VerifyLicense',
  },
  FL: {
    ABA: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    ADC: 'https://online-flcb.elicense.tylerapp.com/Lookup/LicenseLookup.aspx',
    CP: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    CSW: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    DO: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    MD: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    MFT: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
    PC: 'https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders',
  },
  GA: {
    ABA: '',
    ADC: 'https://adacbga.certemy.com/public-registry/831a513b-9eef-416b-8fa5-b793e6505d86',
    CP: 'https://verify.sos.ga.gov/verification/Search.aspx',
    CSW: 'https://verify.sos.ga.gov/verification/Search.aspx',
    DO: 'https://gcmb.mylicense.com/verification/',
    MD: 'https://gcmb.mylicense.com/verification/',
    MFT: 'https://verify.sos.ga.gov/verification/Search.aspx',
    PC: 'https://verify.sos.ga.gov/verification/Search.aspx',
  },
  HI: {
    ABA: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    ADC: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    CP: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    CSW: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    DO: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    MD: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    MFT: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
    PC: 'https://mypvl.dcca.hawaii.gov/public-license-search/',
  },
  IA: {
    ABA: 'https://amanda-portal.idph.state.ia.us/ibpl/portal/#/license/license-query',
    ADC: 'https://iowabc.org/credentials/verify/',
    CP: 'https://amanda-portal.idph.state.ia.us/ibpl/portal/#/license/license-query',
    CSW: 'https://amanda-portal.idph.state.ia.us/ibpl/portal/#/license/license-query',
    DO: 'https://amanda-portal.idph.state.ia.us/ibm/portal/#/license/license_query',
    MD: 'https://amanda-portal.idph.state.ia.us/ibm/portal/#/license/license_query',
    MFT: 'https://amanda-portal.idph.state.ia.us/ibpl/portal/#/license/license-query',
    PC: 'https://amanda-portal.idph.state.ia.us/ibpl/portal/#/license/license-query',
  },
  ID: {
    ABA: '',
    ADC: 'https://www.ibadcc.org/directory',
    CP: 'https://apps.dopl.idaho.gov/DOPLPublic/LPRBrowser.aspx',
    CSW: 'https://edopl.idaho.gov/OnlineServices/_/#1',
    DO: 'https://edopl.idaho.gov/OnlineServices/_/#1',
    MD: 'https://edopl.idaho.gov/OnlineServices/_/#1',
    MFT: 'https://edopl.idaho.gov/OnlineServices/_/#1',
    PC: 'https://edopl.idaho.gov/OnlineServices/_/#1',
  },
  IL: {
    ABA: '',
    ADC: 'https://iaodapca.org/Members/Member-Directory',
    CP: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
    CSW: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
    DO: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
    MD: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
    MFT: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
    PC: 'https://online-dfpr.micropact.com/lookup/licenselookup.aspx',
  },
  IN: {
    ABA: '',
    ADC: 'https://mylicense.in.gov/EVerification/Search.aspx',
    CP: 'https://mylicense.in.gov/EVerification/Search.aspx',
    CSW: 'https://mylicense.in.gov/EVerification/Search.aspx',
    DO: 'https://mylicense.in.gov/EVerification/Search.aspx',
    MD: 'https://mylicense.in.gov/EVerification/Search.aspx',
    MFT: 'https://mylicense.in.gov/EVerification/Search.aspx',
    PC: 'https://mylicense.in.gov/EVerification/Search.aspx',
  },
  KS: {
    ABA: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
    ADC: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
    CP: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
    CSW: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
    DO: 'https://www.kansas.gov/ssrv-ksbhada/search.html',
    MD: 'https://www.kansas.gov/ssrv-ksbhada/search.html',
    MFT: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
    PC: 'https://licensing.ks.gov/Verification_BSRB/Search.aspx',
  },
  KY: {
    ABA: 'https://oop.ky.gov/',
    ADC: 'https://oop.ky.gov/',
    CP: 'https://oop.ky.gov/',
    CSW: 'https://secure.kentucky.gov/formservices/BSW/LicenseVerification/search',
    DO: 'http://web1.ky.gov/GenSearch/LicenseSearch.aspx?AGY=5',
    MD: 'http://web1.ky.gov/GenSearch/LicenseSearch.aspx?AGY=5',
    MFT: 'https://oop.ky.gov/',
    PC: 'https://oop.ky.gov/',
  },
  LA: {
    ABA: 'https://lababoard.org/lba-licensure-list/',
    ADC: 'https://app.certemy.com/public-registry/8ca73d3d-b51c-42b9-9e44-892b2411d264',
    CP: 'https://www.lsbepportal.com/licensee-search',
    CSW: 'https://app.certemy.com/public-registry/17d42be8-6299-4d9b-897c-5cb58ed8dca0',
    DO: 'https://online.lasbme.org/#/verifylicense',
    MD: 'https://online.lasbme.org/#/verifylicense',
    MFT: 'https://www.lpcboard.org/licensee-search',
    PC: 'https://www.lpcboard.org/licensee-search',
  },
  MA: {
    ABA: 'https://checkahealthlicense.mass.gov/',
    ADC: 'https://hhsvgapps03.hhs.state.ma.us/elicensing-pubweb/couns/lookup.htm',
    CP: 'https://checkahealthlicense.mass.gov/',
    CSW: 'https://checkahealthlicense.mass.gov/',
    DO: 'https://findmydoctor.mass.gov/',
    MD: 'https://findmydoctor.mass.gov/',
    MFT: 'https://checkahealthlicense.mass.gov/',
    PC: 'https://checkahealthlicense.mass.gov/',
  },
  MD: {
    ABA: 'https://mdbnc.health.maryland.gov/psychverification',
    ADC: 'https://mdbnc.health.maryland.gov/pctverification',
    CP: 'https://mdbnc.health.maryland.gov/psychverification',
    CSW: 'https://mdbnc.health.maryland.gov/bsweverification',
    DO: 'https://www.mbp.state.md.us/bpqapp/',
    MD: 'https://www.mbp.state.md.us/bpqapp/',
    MFT: 'https://mdbnc.health.maryland.gov/pctverification',
    PC: 'https://mdbnc.health.maryland.gov/pctverification',
  },
  ME: {
    ABA: '',
    ADC: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx',
    CP: 'https://www.pfr.maine.gov/almsonline/almsquery/SearchIndividual.aspx',
    CSW: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx',
    DO: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx',
    MD: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx',
    MFT: 'https://www.pfr.maine.gov/almsonline/almsquery/SearchIndividual.aspx',
    PC: 'https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx',
  },
  MI: {
    ABA: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    ADC: 'https://mcbap.certemy.com/public-registry/3b1487e7-9ca6-4b71-b014-1f649cfe0e24',
    CP: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    CSW: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    DO: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    MD: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    MFT: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
    PC: 'https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx',
  },
  MN: {
    ABA: '',
    ADC: 'https://bht.hlb.state.mn.us/#/onlineEntitySearch',
    CP: 'https://mnit.force.com/license/SearchBox',
    CSW: 'https://soc.hlb.state.mn.us/#/services/onlineLicenseSearch',
    DO: 'https://bmp.hlb.state.mn.us/#/onlineEntitySearch',
    MD: 'https://bmp.hlb.state.mn.us/#/onlineEntitySearch',
    MFT: 'https://mft.hlb.state.mn.us/#/services/onlineLicenseSearch',
    PC: 'https://bht.hlb.state.mn.us/#/onlineEntitySearch',
  },
  MS: {
    ABA: 'https://www.msbop.ms.gov/secure/licensesearch.asp',
    ADC: '',
    CP: 'https://www.msbop.ms.gov/secure/licensesearch.asp',
    CSW: 'https://www.swmft.webapps.ms.gov/publicview/publicindsearch.aspx',
    DO: 'https://gateway.msbml.ms.gov/verification/search.aspx',
    MD: 'https://gateway.msbml.ms.gov/verification/search.aspx',
    MFT: 'https://www.swmft.webapps.ms.gov/publicview/publicindsearch.aspx',
    PC: 'https://www.lpc.ms.gov/secure/licensesearch.asp',
  },
  MO: {
    ABA: 'https://pr.mo.gov/licensee-search-division.asp',
    ADC: 'http://msapcbdatabase.com/',
    CP: 'https://pr.mo.gov/licensee-search-division.asp',
    CSW: 'https://pr.mo.gov/licensee-search-division.asp',
    DO: 'https://pr.mo.gov/licensee-search-division.asp',
    MD: 'https://pr.mo.gov/licensee-search-division.asp',
    MFT: 'https://pr.mo.gov/licensee-search-division.asp',
    PC: 'https://pr.mo.gov/licensee-search-division.asp',
  },
  MT: {
    ABA: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    ADC: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    CP: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    CSW: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    DO: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    MD: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    MFT: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
    PC: 'https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses',
  },
  NC: {
    ABA: 'https://portal.ncbehavioranalystboard.org/Verification/search.aspx',
    ADC: 'https://ncsappb.learningbuilder.com/public/membersearch/search',
    CP: 'http://www.ncpsychologyboard.org/license-verification/',
    CSW: 'https://ncswb.igovsolution.net/online/Lookups/Individual_Lookup.aspx',
    DO: 'https://portal.ncmedboard.org/verification/search.aspx',
    MD: 'https://portal.ncmedboard.org/verification/search.aspx',
    MFT: 'https://www.ncbmft.org/resources-and-information/consumer-resources/verify-a-licensee',
    PC: 'https://portal.ncblcmhc.org/verification/search.aspx',
  },
  ND: {
    ABA: 'https://www.ndsbpe.org/verify/index.asp',
    ADC: 'https://www.ndbace.org/licensing/licensees/',
    CP: 'https://www.ndsbpe.org/verify/index.asp',
    CSW: 'https://www.ndsbpe.org/verify/index.asp',
    DO: 'https://www.ndbom.org/public/find_verify/verify.asp',
    MD: 'https://www.ndbom.org/public/find_verify/verify.asp',
    MFT: '',
    PC: 'https://portal.ncblcmhc.org/verification/search.aspx',
  },
  NE: {
    ABA: '',
    ADC: 'https://www.nebraska.gov/LISSearch/search.cgi',
    CP: 'https://www.nebraska.gov/LISSearch/search.cgi',
    CSW: 'https://www.nebraska.gov/LISSearch/search.cgi',
    DO: 'https://www.nebraska.gov/LISSearch/search.cgi',
    MD: 'https://www.nebraska.gov/LISSearch/search.cgi',
    MFT: 'https://www.nebraska.gov/LISSearch/search.cgi',
    PC: 'https://www.nebraska.gov/LISSearch/search.cgi',
  },
  NH: {
    ABA: '',
    ADC: 'https://forms.nh.gov/licenseverificationtest/',
    CP: 'https://forms.nh.gov/licenseverificationtest/',
    CSW: 'https://forms.nh.gov/licenseverificationtest/',
    DO: 'https://forms.nh.gov/licenseverificationtest/',
    MD: 'https://forms.nh.gov/licenseverificationtest/',
    MFT: 'https://forms.nh.gov/licenseverificationtest/',
    PC: 'https://forms.nh.gov/licenseverificationtest/',
  },
  NJ: {
    ABA: '',
    ADC: 'https://newjersey.mylicense.com/verification/Search.aspx',
    CP: 'https://newjersey.mylicense.com/verification/Search.aspx',
    CSW: 'https://newjersey.mylicense.com/verification/Search.aspx',
    DO: 'https://newjersey.mylicense.com/verification/Search.aspx',
    MD: 'https://newjersey.mylicense.com/verification/Search.aspx',
    MFT: 'https://newjersey.mylicense.com/verification/Search.aspx',
    PC: 'https://newjersey.mylicense.com/verification/Search.aspx',
  },
  NM: {
    ABA: '',
    ADC: 'https://nmrldlpi.my.site.com/bcd/s/rld-public-search',
    CP: 'https://nmrldlpi.my.site.com/bcd/s/rld-public-search',
    CSW: 'https://nmrldlpi.my.site.com/bcd/s/rld-public-search',
    DO: 'http://docfinder.docboard.org/nm/',
    MD: 'http://docfinder.docboard.org/nm/',
    MFT: 'https://nmrldlpi.my.site.com/bcd/s/rld-public-search',
    PC: 'https://nmrldlpi.my.site.com/bcd/s/rld-public-search',
  },
  NV: {
    ABA: 'https://nvba.certemy.com/public-registry/cf634e05-338d-4856-8fce-d0104b351632',
    ADC: 'https://nvadgc.us.thentiacloud.net/webs/nvadgc/register/',
    CP: 'https://nbop.securerims.com/DirectoryofPsychologists.php',
    CSW: 'https://services.socwork.nv.gov/verify/index.asp',
    DO: 'https://nsbom.portalus.thentiacloud.net/webs/portal/register/#/search',
    MD: 'https://nsbme.us.thentiacloud.net/webs/nsbme/register/#/search',
    MFT: 'https://nvboe.certemy.com/public-registry/00b35480-36a9-4898-a052-c13871cce91e',
    PA: 'https://nsbme.us.thentiacloud.net/webs/nsbme/register/#/search',
    PC: 'https://nvboe.certemy.com/public-registry/00b35480-36a9-4898-a052-c13871cce91e',
    RT: 'https://nsbme.us.thentiacloud.net/webs/nsbme/register/#/search',
  },
  NY: {
    ABA: 'http://www.op.nysed.gov/opsearches.htm#',
    ADC: 'http://www.op.nysed.gov/opsearches.htm#',
    CP: 'http://www.op.nysed.gov/opsearches.htm#',
    CSW: 'http://www.op.nysed.gov/opsearches.htm#',
    DO: 'http://www.op.nysed.gov/opsearches.htm#',
    MD: 'http://www.op.nysed.gov/opsearches.htm#',
    MFT: 'http://www.op.nysed.gov/opsearches.htm#',
    PC: 'http://www.op.nysed.gov/opsearches.htm#',
  },
  OH: {
    ABA: 'https://elicense.ohio.gov/oh_verifylicense#',
    ADC: 'https://elicense.ohio.gov/oh_verifylicense',
    CP: 'https://elicense.ohio.gov/oh_verifylicense',
    CSW: 'https://elicense.ohio.gov/oh_verifylicense',
    DO: 'https://elicense.ohio.gov/oh_verifylicense',
    MD: 'https://elicense.ohio.gov/oh_verifylicense',
    MFT: 'https://elicense.ohio.gov/oh_verifylicense',
    PC: 'https://elicense.ohio.gov/oh_verifylicense',
  },
  OK: {
    ABA: 'https://www.ok.gov/OSBEP/_app/search/index.php',
    ADC: 'https://www.okdrugcounselors.org/members.php',
    CP: 'https://pay.apps.ok.gov/OSBEP/_app/search/index.php',
    CSW: 'https://obbhl.us.thentiacloud.net/webs/obbhl/register/#',
    DO: 'https://osboe.us.thentiacloud.net/webs/osboe/register/#?type=number',
    MD: 'https://www.okmedicalboard.org/search',
    MFT: 'https://obbhl.us.thentiacloud.net/webs/obbhl/register/#',
    PC: 'https://obbhl.us.thentiacloud.net/webs/obbhl/register/#',
  },
  OR: {
    ABA: 'https://elite.hlo.state.or.us/OHLOPublicR/LPRBrowser.aspx',
    ADC: 'https://www.mhacbo.org/en/registry/',
    CP: 'https://obop.us.thentiacloud.net/webs/obop/register/#',
    CSW: 'https://blsw.us.thentiacloud.net/webs/blsw/register/#',
    DO: 'https://omb.oregon.gov/search',
    MD: 'https://omb.oregon.gov/search',
    MFT: 'https://oblpct.us.thentiacloud.net/webs/oblpct/register/',
    PC: 'https://oblpct.us.thentiacloud.net/webs/oblpct/register/',
  },
  PA: {
    ABA: 'https://www.pals.pa.gov/#!/page/search',
    ADC: 'https://www.pals.pa.gov/#!/page/search',
    CP: 'https://www.pals.pa.gov/#!/page/search',
    CSW: 'https://www.pals.pa.gov/#!/page/search',
    DO: 'https://www.pals.pa.gov/#!/page/search',
    MD: 'https://www.pals.pa.gov/#!/page/search',
    MFT: 'https://www.pals.pa.gov/#!/page/search',
    PC: 'https://www.pals.pa.gov/#!/page/search',
  },
  RI: {
    ABA: 'https://healthri.mylicense.com/verification/',
    ADC: 'https://healthri.mylicense.com/verification/',
    AUD: 'https://healthri.mylicense.com/Verification/',
    CP: 'https://healthri.mylicense.com/verification/',
    CSW: 'https://health.ri.gov/find/licensees/index.php?prof=Social%20Work#foo',
    DO: 'https://healthri.mylicense.com/Verification/',
    MD: 'https://healthri.mylicense.com/Verification/',
    MFT: 'https://healthri.mylicense.com/Verification/',
    PC: 'https://healthri.mylicense.com/Verification/',
    SLP: 'https://healthri.mylicense.com/Verification/',
  },
  SC: {
    ABA: '',
    ADC: 'https://verify.llronline.com/LicLookup/Counselors/Counselors.aspx?div=18&AspxAutoDetectCookieSupport=1',
    CP: 'https://verify.llronline.com/LicLookup/Psyc/Psyc.aspx?div=37',
    CSW: 'https://verify.llronline.com/LicLookup/SW/SW.aspx?div=41&AspxAutoDetectCookieSupport=1',
    DO: 'https://verify.llronline.com/LicLookup/Med/Med.aspx?div=16',
    MD: 'https://verify.llronline.com/LicLookup/Med/Med.aspx?div=16',
    MFT: 'https://verify.llronline.com/LicLookup/Counselors/Counselors.aspx?div=18',
    PC: 'https://verify.llronline.com/LicLookup/Counselors/Counselors.aspx?div=18',
  },
  SD: {
    ABA: 'https://www.sdboards.org/dss/aba/verify/',
    ADC: 'https://dss.sd.gov/licensingboards/bapp/verification.aspx',
    CP: 'https://www.sdboards.org/dss/psych/verify/',
    CSW: 'https://www.sdboards.org/sw/verify/',
    DO: 'https://www.sdbmoe.gov/sdbmoe-licensee-lookup/',
    MD: 'https://www.sdbmoe.gov/sdbmoe-licensee-lookup/',
    MFT: 'https://www.sdboardofcounselors.org/verify/',
    PC: 'https://www.sdboardofcounselors.org/verify/',
  },
  TN: {
    ABA: 'https://apps.health.tn.gov/Licensure/',
    ADC: 'https://apps.health.tn.gov/Licensure/',
    CP: 'https://apps.health.tn.gov/Licensure/',
    CSW: 'https://apps.health.tn.gov/Licensure/default.aspx',
    DO: 'https://apps.health.tn.gov/Licensure/default.aspx',
    MD: 'https://apps.health.tn.gov/Licensure/default.aspx',
    MFT: 'https://apps.health.tn.gov/Licensure/default.aspx',
    PC: 'https://apps.health.tn.gov/Licensure/',
  },
  TX: {
    ABA: 'https://www.tdlr.texas.gov/LicenseSearch/',
    ADC: 'https://bhec.texas.gov/verify-a-license/',
    CP: 'https://bhec.texas.gov/verify-a-license/',
    CSW: 'https://bhec.texas.gov/verify-a-license/',
    DO: 'https://profile.tmb.state.tx.us/Search.aspx',
    MD: 'https://profile.tmb.state.tx.us/Search.aspx',
    MFT: 'https://bhec.texas.gov/verify-a-license/',
    PC: 'https://bhec.texas.gov/verify-a-license/',
  },
  UT: {
    ABA: 'https://secure.utah.gov/llv/search/index.html',
    ADC: 'https://secure.utah.gov/llv/search/index.html',
    CP: 'https://secure.utah.gov/llv/search/index.html',
    CSW: 'https://secure.utah.gov/llv/search/index.html',
    DO: 'https://secure.utah.gov/llv/search/index.html',
    MD: 'https://secure.utah.gov/llv/search/index.html',
    MFT: 'https://secure.utah.gov/llv/search/index.html',
    PC: 'https://secure.utah.gov/llv/search/index.html',
  },
  VA: {
    ABA: 'https://dhp.virginiainteractive.org/Lookup/Index',
    ADC: 'https://dhp.virginiainteractive.org/lookup/index',
    CP: 'https://dhp.virginiainteractive.org/Lookup/Index',
    CSW: 'https://dhp.virginiainteractive.org/Lookup/Index',
    DO: 'https://dhp.virginiainteractive.org/Lookup/Index',
    MD: 'https://dhp.virginiainteractive.org/Lookup/Index',
    MFT: 'https://dhp.virginiainteractive.org/lookup/index',
    PC: 'https://dhp.virginiainteractive.org/lookup/index',
  },
  VT: {
    ABA: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    ADC: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    CP: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    CSW: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    DO: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    MD: 'https://mpb.health.vermont.gov/Lookup/LicenseLookup.aspx',
    MFT: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
    PC: 'https://secure.professionals.vermont.gov/prweb/PRServletCustom/app/NGLPGuestUser_/V9csDxL3sXkkjMC_FR2HrA*/!STANDARD?UserIdentifier=LicenseLookupGuestUser',
  },
  WA: {
    ABA: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    ADC: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    CP: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    CSW: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    DO: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    MD: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    MFT: 'https://fortress.wa.gov/doh/providercredentialsearch/',
    PC: 'https://fortress.wa.gov/doh/providercredentialsearch/',
  },
  WI: {
    ABA: 'https://licensesearch.wi.gov/',
    ADC: 'https://licensesearch.wi.gov/',
    CP: 'https://licensesearch.wi.gov/',
    CSW: 'https://licensesearch.wi.gov/',
    DO: 'https://licensesearch.wi.gov/',
    MD: 'https://licensesearch.wi.gov/',
    MFT: 'https://licensesearch.wi.gov/',
    PC: 'https://licensesearch.wi.gov/',
  },
  WV: {
    ABA: '',
    ADC: 'https://www.wvcbapp.org/find-a-certified-professional',
    CP: 'https://psychbd.wv.gov/license-info/license-search/Pages/default.aspx',
    CSW: 'https://www.wvsocialworkboard.org/Licensure/License-Verification',
    DO: 'https://www.wvbdosteo.org/verify/',
    MD: 'https://wvbom.wv.gov/public/search/',
    MFT: 'https://wvbec.org/counselor-and-therapist-license-verification/',
    PC: 'https://wvbec.org/counselor-and-therapist-license-verification/',
  },
  WY: {
    ABA: '',
    ADC: 'https://mentalhealth.wyo.gov/public/license-verification',
    CP: 'https://psychology.wyo.gov/public/lookup',
    CSW: 'https://mentalhealth.wyo.gov/public/license-verification',
    DO: 'https://wybomv7prod.glsuite.us/GLSuiteWeb/Clients/WYBOM/Public/Licenseesearch.aspx?SearchType=Physician',
    MD: 'https://wybomv7prod.glsuite.us/GLSuiteWeb/Clients/WYBOM/Public/Licenseesearch.aspx?SearchType=Physician',
    MFT: 'https://mentalhealth.wyo.gov/public/license-verification',
    PC: 'https://mentalhealth.wyo.gov/public/license-verification',
  },
};
