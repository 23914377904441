import moment from 'moment';
import {
  EntityDocument,
  EntityEnrollmentDetails,
  FetchOrgEntityEnrollmentsResponse,
  EntityLocationResponse,
  FetchLocationsResponse,
  OrgEntityDetails,
  SingleOrgEntityResponse,
  FetchEntityDocumentsResponse,
  CreateOrgAddressPayload,
} from '#/types';
import {
  apiResponseDateToDate,
  firestoreDateInUtc,
  formatDate,
} from '#/Utils/date';
import {
  PEWorkflowOutreachCorrespondences,
  PEWorkflowOutreachResponse,
} from '#/types/provider-enrollment-workflows.types';
import { get, sortBy } from 'lodash';

export const formatAddress = (address?: CreateOrgAddressPayload): string => {
  let result = '';
  if (address?.addressLine1) {
    result = address.addressLine1;
  }
  if (address?.city) {
    result = `${result}, ${address.city}`;
  }
  if (address?.state) {
    result = `${result}, ${address.state}`;
  }
  if (address?.zip) {
    result = `${result} - ${address.zip}`;
  }
  return result;
};

export const transformFetchOrgEntityByIdResponse = (
  response: SingleOrgEntityResponse,
): OrgEntityDetails => {
  const {
    contactPerson,
    createdAt,
    generalInfo,
    serviceAddress,
    mailingAddress,
    billingAddress,
  } = response;

  return {
    ...response,
    overview: {
      name: generalInfo.name,
      dba: generalInfo?.dba ?? '',
      affiliation: generalInfo?.affiliation,
      tin: generalInfo?.tin,
      npi: generalInfo?.npi,
      specialty: generalInfo?.specialty ?? '',
      primarySpecialty: `${response.primarySpecialty?.name} (${response.primarySpecialty?.specialtyNuccTaxonomyCode})`,
      externalGroupId: response.externalGroupId,
      createdAt: apiResponseDateToDate(createdAt),
      networkEffectiveDate: firestoreDateInUtc(
        generalInfo?.networkEffectiveDate,
      ),
      serviceAddress: formatAddress(serviceAddress),
      mailingAddress: formatAddress(mailingAddress),
      billingAddress: formatAddress(billingAddress),
      phone: contactPerson?.phone,
      fax: contactPerson?.fax,
      states: serviceAddress?.state ? [serviceAddress?.state] : undefined,
      isSoloPractice: generalInfo?.isSoloPractice
        ? generalInfo?.isSoloPractice
        : 'Not Selected',
      practiceType: generalInfo?.practiceType,
      groupType: generalInfo?.groupType,
      website: generalInfo?.website,
      siteLoginUsername: generalInfo.siteLoginUsername,
      deaNumber: generalInfo?.deaNumber,
      sitePassword: generalInfo?.sitePassword,
      locationCount: response.locationCount,
      providerCount: response.providerCount,
      effectiveProviderEnrollmentCount:
        response.effectiveProviderEnrollmentCount,
      unenrolledProviderCount: response.unenrolledProviderCount,
      inProgressProviderEnrollmentCount:
        response.inProgressProviderEnrollmentCount,
      tags: response.tags?.toString(),
    },
    'professional-ids': (response['professional-ids'] || []).map((item) => ({
      id: item.id,
      type: item.type,
      note: item.note,
      number: item.number,
      source: item.source,
      state: item.state,
      issueDate: formatDate(item.issueDate as unknown as string),
      expirationDate: formatDate(item.expirationDate as unknown as string),
    })),
  };
};

export const transformFetchOrgEntityEnrollmentsByIdResponse = (
  response: FetchOrgEntityEnrollmentsResponse,
): EntityEnrollmentDetails => {
  return {
    data: response.data.map((item) => {
      const {
        jurisdiction,
        lineOfBusiness,
        planName,
        payorName,
        planId,
        payorId,
      } = item?.generalInfo || {};

      return {
        id: item.id,
        payorName,
        planName,
        planId,
        payorId,
        jurisdiction,
        lineOfBusiness,
        groupName: item.groupName,
        groupId: item.groupId,
        enrollmentEffectiveDate: item.enrollmentEffectiveDate,
        terminationDate: item.terminationDate,
        terminationInfoDate: item.terminationInfoDate,
        contractDetails: item.contractDetails,
        status: item.status,
        generatedId: item.generatedId,
        providerCount: item.providerCount,
      };
    }),
    total: response.total,
  };
};

export const transformFetchAllPEWorkflowOutreachAttemptsResponse = (
  response: PEWorkflowOutreachResponse,
): PEWorkflowOutreachResponse => {
  const rootMessages = response.correspondences.filter(
    (msg) => !msg.rootMessageId,
  );
  const correspondences: PEWorkflowOutreachCorrespondences[] = rootMessages.map(
    (rootMsg) => {
      if (rootMsg.method !== 'email') return rootMsg;
      const replies = response.correspondences.filter(
        (msg) =>
          msg.rootMessageId &&
          rootMsg.sgMessageId &&
          msg.rootMessageId === rootMsg.sgMessageId,
      );
      return {
        ...rootMsg,
        replies: sortBy(
          replies,
          (reply) => -1 * get(reply, 'createdAt._seconds', 0),
        ),
      };
    },
  );
  return {
    correspondences,
    overview: response.overview,
  };
};

export const transformFetchLocationResponse = (
  response: FetchLocationsResponse,
) => {
  const data = response.data.map((location: EntityLocationResponse) => {
    const { serviceAddress, hospitalAdmittingPrivileges } = location;

    return {
      ...location,
      hospitalAdmittingPrivileges: hospitalAdmittingPrivileges?.map(
        (item: any) => ({
          hospitalName: item.hospitalName,
          effectiveDate: apiResponseDateToDate(item?.effectiveDate)!,
          privilege: item.privilege,
        }),
      ),
      address: formatAddress(serviceAddress),
    };
  });

  return {
    data,
    total: response.total,
  };
};

export const transformFetchDocumentsResponse = (
  response: FetchEntityDocumentsResponse,
) => {
  const currentTime = moment();

  const data = response?.data.map((item: EntityDocument) => {
    const createdAtDate = apiResponseDateToDate(item?.createdAt);
    const timeDifference = currentTime.diff(moment(createdAtDate));
    return {
      ...item,
      createdAtDate,
      timeAgo: moment.duration(timeDifference).humanize(),
    } as EntityDocument;
  });

  return { data, total: response?.total };
};
