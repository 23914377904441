import {
  transformFetchFacilityCredWorkflowDetailsResponse,
  transformFetchFacilityCredWorkflowsResponse,
} from '#/data-transformers/facility-credentialing.transformer';
import {
  FacilitiesCredWorkflowsApiResponse,
  FacilityCredWorkflowDetailsApiResponse,
  FacilityWorkflowDetails,
} from '#/types/facility-credentialing-workflows.types';
import { pickBy } from 'lodash';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { FacilitiesFormData } from '#/components/Facility/FilterForm';
import { PaginationQueryParams } from '#/types/pagination.types';
import {
  AnalystData,
  BulkMoveErrorResponseItem,
  EditOutreachAttemptPayload,
  OutreachAttemptPayload,
  OutreachCorrespondenceList,
} from '#/types';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import { transformFetchAllWorkflowOutreachAttemptsResponse } from '#/data-transformers';
import { CreateFacilityCredWorkflowPayload } from '#/api/facilities';
import { formatDateForBackend } from '#/Utils/date';

const BASE_URL = '/facility-credentialing-workflows';

interface FacilitiesParams extends PaginationQueryParams, FacilitiesFormData {}

export const facilitiesCredentialingApi = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchFacilityCredentialingWorkflows: builder.query<
      FacilitiesCredWorkflowsApiResponse,
      FacilitiesParams
    >({
      query: (formData) => ({
        url: BASE_URL,
        method: 'get',
        params: pickBy(formData),
      }),
      providesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST],
      transformResponse: (response: FacilitiesCredWorkflowsApiResponse) => {
        return transformFetchFacilityCredWorkflowsResponse(response);
      },
    }),
    fetchFacilityCredWorkflowDetails: builder.query<
      FacilityWorkflowDetails,
      { workflowId: string }
    >({
      query: ({ workflowId }) => ({
        url: `/facility-credentialing-workflows/${workflowId}`,
        method: 'get',
      }),
      providesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
      transformResponse(response: FacilityCredWorkflowDetailsApiResponse) {
        return transformFetchFacilityCredWorkflowDetailsResponse(response);
      },
    }),
    fetchFacilityAnalysts: builder.query<AnalystData[], void>({
      query: () => ({
        url: '/facility-credentialing-workflows/analysts',
        method: 'GET',
      }),
      providesTags: [TAGS_MAP.FACILITY_ANALYST],
    }),
    assignFacilityWorkflows: builder.mutation<
      void,
      {
        workflowIds: string[];
        userIds: string[];
        filters?: ProviderFormData;
      }
    >({
      query: (args) => ({
        url: '/facility-credentialing-workflows/assign-to-analysts',
        method: 'PATCH',
        data: args,
      }),
      invalidatesTags: () => [
        TAGS_MAP.FACILITY_ANALYST,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST,
      ],
    }),
    createFacilityCredWorkflow: builder.mutation<
      void,
      CreateFacilityCredWorkflowPayload
    >({
      query: (data) => ({
        url: '/facility-credentialing-workflows',
        method: 'post',
        data,
      }),
      invalidatesTags: [
        TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
        TAGS_MAP.FACILITY,
        TAGS_MAP.FACILITY_LIST,
      ],
    }),
    moveFacilityFromInProgressToPsvCompleteByCertifyOs: builder.mutation<
      unknown,
      string
    >({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-from-in-progress-to-psv-complete-by-certifyos`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityToInProgress: builder.mutation<unknown, string>({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-in-progress`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityToNotStarted: builder.mutation<unknown, string>({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-not-started`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityFromPsvCompleteByCertifyosToPsvReady: builder.mutation<
      void,
      string
    >({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-from-psv-complete-by-certifyos-to-psv-ready`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    generateFacilityCredWorkflowPsv: builder.mutation<void, string>({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/generate-psv`,
        method: 'post',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    fetchFacilityCredWorkflowOutreachCorrespondences: builder.query<
      OutreachCorrespondenceList,
      string
    >({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/outreach-correspondences`,
        method: 'GET',
      }),
      transformResponse: transformFetchAllWorkflowOutreachAttemptsResponse,
      providesTags: [TAGS_MAP.FACILITY_OUTREACH_CORRESPONDENCE],
    }),
    createFacilityOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        payload: OutreachAttemptPayload;
      }
    >({
      query: ({ workflowId, payload }) => {
        if (
          payload?.method === 'email' &&
          payload?.attachments &&
          payload.attachments.length > 0
        ) {
          const formData = new FormData();

          // Append fields to FormData
          formData.append('method', 'email');
          if (payload.reason && payload.reason.length > 0) {
            payload.reason.forEach((reasonItem, index) => {
              formData.append(`reason[${index}]`, reasonItem);
            });
          }
          if (payload.to && payload.to.length > 0) {
            payload.to.forEach((toItem, index) => {
              formData.append(`to[${index}]`, toItem);
            });
          }
          if (payload.subject) formData.append('subject', payload.subject);
          if (payload.body) formData.append('body', payload.body);
          if (payload.isEmailSaveOnly)
            formData.append(
              'isEmailSaveOnly',
              payload.isEmailSaveOnly.toString(),
            );
          if (payload.attachments && payload.attachments.length > 0) {
            payload.attachments.forEach((file) => {
              formData.append('files', file);
            });
          }

          return {
            url: `${BASE_URL}/${workflowId}/outreach-attempt`,
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        } else {
          return {
            url: `${BASE_URL}/${workflowId}/outreach-attempt`,
            method: 'POST',
            body: payload,
          };
        }
      },
      invalidatesTags: [
        TAGS_MAP.FACILITY_OUTREACH_CORRESPONDENCE,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
      ],
    }),
    updateFacilityOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        outreachAttemptId: string;
        payload: EditOutreachAttemptPayload;
      }
    >({
      query: ({ workflowId, outreachAttemptId, payload }) => ({
        url: `${BASE_URL}/${workflowId}/outreach-attempt/${outreachAttemptId}`,
        method: 'put',
        data: payload,
      }),
      invalidatesTags: [
        TAGS_MAP.FACILITY_OUTREACH_CORRESPONDENCE,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
      ],
    }),
    deleteFacilityOutreachAttempt: builder.mutation<
      void,
      {
        workflowId: string;
        noteId: string;
      }
    >({
      query: ({ workflowId, noteId }) => ({
        url: `${BASE_URL}/${workflowId}/outreach-attempt/${noteId}`,
        method: 'delete',
      }),
      invalidatesTags: [
        TAGS_MAP.FACILITY_OUTREACH_CORRESPONDENCE,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
      ],
    }),
    updateFacilityCredentialingWorkflowToApproved: builder.mutation<
      unknown,
      { workflowId: string; credStatusDate?: Date; medicalDirectorId?: string }
    >({
      query: ({ workflowId, credStatusDate, medicalDirectorId }) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-cred-approved`,
        method: 'put',
        data: {
          credStatusDate: formatDateForBackend(credStatusDate),
          medicalDirectorId,
        },
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    updateFacilityCredentialingWorkflowToOutreachInProgress: builder.mutation<
      unknown,
      string
    >({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-outreach-in-progress`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityToDataMissing: builder.mutation<unknown, string>({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-data-missing`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    updateFacilityCredentialingWorkflowToDenied: builder.mutation<
      unknown,
      { workflowId: string; credStatusDate?: Date; medicalDirectorId?: string }
    >({
      query: ({ workflowId, credStatusDate, medicalDirectorId }) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-cred-denied`,
        method: 'put',
        data: {
          credStatusDate: formatDateForBackend(credStatusDate),
          medicalDirectorId,
        },
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityToCancel: builder.mutation<
      void,
      { workflowId: string; reason: string }
    >({
      query: ({ workflowId, reason }) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-cancel`,
        method: 'put',
        data: { reason },
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveFacilityToCredCom: builder.mutation<void, string>({
      query: (workflowId) => ({
        url: `${BASE_URL}/${workflowId}/steps/move-to-cred-committee`,
        method: 'put',
      }),
      invalidatesTags: [TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL],
    }),
    moveBulkFacilityToCredCom: builder.mutation<
      BulkMoveErrorResponseItem[],
      string[]
    >({
      query: (facilityIds) => ({
        url: `${BASE_URL}/steps/move-to-cred-committee`,
        method: 'put',
        data: { facilityIds },
      }),
      invalidatesTags: [
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST,
      ],
    }),
    assignFacilityWorkflowsToMe: builder.mutation<
      void,
      {
        workflowIds: string[];
        filters?: ProviderFormData;
      }
    >({
      query: (args) => ({
        url: '/facility-credentialing-workflows/assign-to-me',
        method: 'PATCH',
        data: args,
      }),
      invalidatesTags: () => [
        TAGS_MAP.FACILITY_ANALYST,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST,
      ],
    }),
    unassignFacilityWorkflowsFromMe: builder.mutation<
      void,
      {
        workflowIds: string[];
        filters?: ProviderFormData;
      }
    >({
      query: (args) => ({
        url: '/facility-credentialing-workflows/unassign-from-me',
        method: 'PATCH',
        data: args,
      }),
      invalidatesTags: () => [
        TAGS_MAP.FACILITY_ANALYST,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_DETAIL,
        TAGS_MAP.FACILITY_CRED_WORKFLOW_LIST,
      ],
    }),
  }),
});

export const {
  useFetchFacilityCredentialingWorkflowsQuery,
  useFetchFacilityCredWorkflowDetailsQuery,
  useFetchFacilityAnalystsQuery,
  useAssignFacilityWorkflowsMutation,
  useCreateFacilityCredWorkflowMutation,
  useMoveFacilityFromInProgressToPsvCompleteByCertifyOsMutation,
  useMoveFacilityFromPsvCompleteByCertifyosToPsvReadyMutation,
  useUpdateFacilityCredentialingWorkflowToApprovedMutation,
  useUpdateFacilityCredentialingWorkflowToDeniedMutation,
  useGenerateFacilityCredWorkflowPsvMutation,
  useCreateFacilityOutreachAttemptMutation,
  useFetchFacilityCredWorkflowOutreachCorrespondencesQuery,
  useUpdateFacilityCredentialingWorkflowToOutreachInProgressMutation,
  useDeleteFacilityOutreachAttemptMutation,
  useUpdateFacilityOutreachAttemptMutation,
  useMoveFacilityToCancelMutation,
  useMoveFacilityToDataMissingMutation,
  useMoveFacilityToInProgressMutation,
  useMoveFacilityToNotStartedMutation,
  useMoveFacilityToCredComMutation,
  useMoveBulkFacilityToCredComMutation,
  useAssignFacilityWorkflowsToMeMutation,
  useUnassignFacilityWorkflowsFromMeMutation,
} = facilitiesCredentialingApi;
