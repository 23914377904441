import { parse, format, differenceInDays, isValid, parseISO } from 'date-fns';
import { isDate, isString } from 'lodash';
import { ApiResponseDateTime, FirestoreTimestamp } from './types';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';

export const DATE_FORMAT = 'MM/dd/yyyy';

export const formatDate = (
  date: Date | string | undefined | null,
  dateFormat = 'MM/dd/yyyy',
) => {
  if (!date) return '';
  if (
    date instanceof Date ||
    (typeof date === 'string' && !isNaN(Date.parse(date)))
  ) {
    return format(new Date(date), dateFormat);
  }
  return '';
};

export const formatDateTime = (date: Date | string | undefined | null) => {
  if (!date) return '';
  if (
    date instanceof Date ||
    (typeof date === 'string' && !isNaN(Date.parse(date)))
  ) {
    const d = new Date(date);
    return format(d, 'MM/dd/yyyy HH:mm');
  }
  return '';
};

export const dateWithoutTimeZone = (dateInput: Date) => {
  return parse(
    `${
      dateInput.getMonth() + 1
    }/${dateInput.getDate()}/${dateInput.getFullYear()}`,
    'MM/dd/yyyy',
    new Date(),
  );
};

export const dateInUtc = (dateInput: Date | undefined) => {
  if (!dateInput) return;
  return parse(
    `${
      dateInput.getUTCMonth() + 1
    }/${dateInput.getUTCDate()}/${dateInput.getUTCFullYear()}`,
    'MM/dd/yyyy',
    new Date(),
  );
};

export const apiResponseDateToDate = (
  dateInput?: ApiResponseDateTime,
): Date | undefined => {
  if (
    isString(dateInput) &&
    (isValid(dateInput) || isValid(parseISO(dateInput)))
  ) {
    return new Date(dateInput);
  } else if (isDate(dateInput)) {
    return dateInput;
  } else if ((dateInput as FirestoreTimestamp)?._seconds) {
    //@ts-ignore
    return new Date(dateInput._seconds * 1000);
  }

  return undefined;
};

export const firestoreDateInUtc = (
  dateInput: ApiResponseDateTime | undefined,
) => {
  const date = apiResponseDateToDate(dateInput);
  return date ? dateInUtc(date) : date; // terinary would be undefined
};

export const dateDiffInDays = (from: Date, to: Date) => {
  return differenceInDays(to, from);
};

/* 
To avoid the Backend issue where it converts in the way below in the DTOs we are 
adding this function to standardize how we pass the dates to the BE.
"9/10/2023" -> 2023-09-09T23:00:00.000Z
"2023-10-09" -> 2023-10-09T00:00:00.000Z
this is how BE convert string into dates in dto
*/
export const formatDateForBackend = (
  date: Date | undefined,
): string | undefined => {
  return date && format(date, 'yyyy-MM-dd');
};

export const parseDate = (date: string | number | Date | null | undefined) => {
  return date ? new Date(date) : null;
};

export const convertToLocaleDate = (
  date: ApiResponseDateTime | undefined,
  timezone?: string,
): Date | undefined => {
  if (!date) return undefined;
  const responseDate = apiResponseDateToDate(date);
  if (!responseDate) return undefined;
  if (!timezone) return new Date(responseDate);
  const zonedTime = utcToZonedTime(responseDate, timezone);
  const zonedDate = formatTz(zonedTime, 'MM-dd-yyyy', {
    timeZone: timezone,
  });
  return new Date(zonedDate);
};
