import {
  CONFIG_TYPES,
  NOTIFICATION_EVENT,
  NOTIFICATION_SECTIONS,
} from '../Utils/Constants';
import { OrganizationMembership } from '#/redux/reducers/organizationMembershipReducer';
import { NOTIFICATION_TYPE } from '#/Utils/Constants';
import { ApiResponseDateTime } from '#/Utils/types';
import { ReferenceLinksConfig } from '../components/OrganizationSettings/ConfigurationsTab/ReferenceLinksSection';
import { MembershipOrganization } from './memberships.types';
import { PaginationQueryParams } from './pagination.types';

export interface FetchOrgsQueryParams extends PaginationQueryParams {
  q?: string;
  isSuperOrg?: boolean;
}

export interface FetchOrgListApiResponse {
  orgs: {
    createdAt: ApiResponseDateTime;
    website: string;
    createdBy: string;
    name: string;
    id: string;
    type: string;
    monitoringEnabled: boolean;
    monitoringWorkflow: boolean;
    isForceMonitoringWithoutCredentialing: boolean;
    monitoringDayOfTheMonth: string;
    lastMonitoringDate: ApiResponseDateTime;
    numberOfUsers: number;
  }[];
  count: number;
}

export type OrgList = {
  orgs: {
    createdAt?: Date;
    website: string;
    createdBy: string;
    name: string;
    id: string;
    type: string;
    monitoringEnabled: boolean;
    monitoringWorkflow: boolean;
    isForceMonitoringWithoutCredentialing: boolean;
    monitoringDayOfTheMonth: string;
    lastMonitoringDate?: Date;
    numberOfUsers: number;
  }[];
  count: number;
};

export interface RdbAirtablePayload {
  title: string;
  url: string;
  dataSyncEnabled: boolean;
}

export interface RdbUserResponse {
  firstName: string;
  lastName: string;
  id: string;
}

export interface FetchOrgRdbResponse {
  emailConfigs: RdbEmailConfig[];
  slaFilesPerAnalyst?: number;
  slaDaysPerFile?: number;
  monitoringWithoutCredentialing?: boolean;
  recredentialingMonths?: number;
  notifyOnProviderDataUpdate?: boolean;
  airtableConfig: RdbAirtablePayload;
  fileTypeConfig?: RdbFileTypeConfig;
  facilityFileTypeConfig?: RdbFacilityFileTypeConfig;
  createdAt: ApiResponseDateTime;
  dashboardLookerUrl?: string;
  enrollmentsAirtableUrl?: string;
  psvRequirementsFileUrl?: string;
  website: string;
  contactEmail: string;
  accessPermissions: AccessPermissionsType;
  dataPropagationConfiguration: DataPropagationConfigurationType;
  name: string;
  type: string;
  contactPhone: string;
  numberOfUsers: number;
  caqhEnabled?: boolean;
  npdbEnrollmentEnabled?: boolean;
  id: string;
  status?: 'active' | 'inactive';
  createdBy?: RdbUserResponse;
}

export interface RdbEmailConfig {
  autoSend: boolean;
  templateId?: string;
  ccEmails?: string;
  type: string;
  sendEmail: boolean;
}

export interface RdbFileTypeConfig {
  npdbEnabled: boolean;
  mmprReportsEnabled: boolean;
  mmprReportsThreshold: number;
  mmprReportsScore: number;
  sanctionSamEnabled: boolean;
  npdbAdverseActionsFoundScore: number;
  sanctionSamThreshold: number;
  sanctionSamScore: number;
  nonMmprReportsEnabled: boolean;
  nonMmprReportsScore: number;
  sanctionMooEnabled: boolean;
  sanctionMooThreshold: number;
  sanctionMooScore: number;
  cleanThreshold: number;
  patientsDeceasedEnabled: boolean;
  patientsDeceasedThreshold: number;
  patientsDeceasedScore: number;
  licensureActionsEnabled: boolean;
  licensureActionsThreshold: number;
  licensureActionsScore: number;
  recentYearsEnabled: boolean;
  recentYearsThreshold: number;
  recentYearsScore: number;
  totalMmprPaymentEnabled: boolean;
  totalMmprPaymentThreshold: number;
  totalMmprPaymentScore: number;
  sanctionsEnabled: boolean;
  sanctionOigEnabled: boolean;
  sanctionOigThreshold: number;
  sanctionOigScore: number;
  nonMmprReportsThreshold: number;
}

export interface RdbFacilityFileTypeConfig {
  sanctionsMatchFoundEnabled: boolean;
  sanctionsMatchFoundThreshold: number;
  sanctionsMatchFoundScore: number;
  npdbAdverseActionsFoundEnabled: boolean;
  npdbAdverseActionsFoundThreshold: number;
  npdbAdverseActionsFoundScore: number;
  missingLicensesEnabled: boolean;
  missingLicensesThreshold: number;
  missingLicensesScore: number;
  cleanThreshold: number;
  expiredLicensesFoundEnabled: boolean;
  expiredLicensesFoundThreshold: number;
  expiredLicensesFoundScore: number;
  sanctionsEnabled: boolean;
  licensesEnabled: boolean;
  npdbEnabled: boolean;
}

export interface FetchOrgResponse {
  emailConfigs: EmailConfigsType;
  slaConfigs: SLAConfigsType;
  airtable?: AirtablePayload;
  fileTypeConfigs: FileTypeConfigsPayload;
  referenceLinksConfig?: ReferenceLinksConfig;
  facilityFileTypeConfigs: FacilityFileTypeConfigsPayload;
  createdAt: ApiResponseDateTime;
  createdByName: string;
  dashboardLookerUrl?: string;
  dashboards?: {
    supervisorDashboard?: string;
  };
  defaultFileTypeConfigOverrides?: FileTypeConfigOverridesType;
  enrollmentsAirtableUrl?: string;
  website: string;
  contactEmail: string;
  createdBy: string;
  accessPermissions: AccessPermissionsType;
  name: string;
  type: string;
  contactPhone: string;
  numberOfUsers: number;
  caqhEnabled?: boolean;
  npdbEnrollmentEnabled?: boolean;
  id: string;
  status?: 'active' | 'inactive';
  psvConfigs?: PsvConfigs;
  dataPropagationConfiguration: DataPropagationConfigurationType;
}

export type PsvConfigs = {
  isMultiplePsvEnabled?: boolean;
};

export type FileLogicFieldType = {
  enabled?: boolean;
  threshold?: number;
  score?: number;
};

export type FileTypeConfigsPayload = {
  npdbEnabled: boolean;
  recentYears: FileLogicFieldType;
  mmprReports: FileLogicFieldType;
  nonMmprReports: FileLogicFieldType;
  patientsDeceased: FileLogicFieldType;
  totalMmprPayment: FileLogicFieldType;
  licensureActionsEnabled: boolean;
  licensureActions: FileLogicFieldType;
  sanctionsEnabled: boolean;
  sanctionMOO: FileLogicFieldType;
  sanctionOIG: FileLogicFieldType;
  sanctionSAM: FileLogicFieldType;
  cleanThreshold: number;
  [key: string]: boolean | number | FileLogicFieldType;
};

export type FacilityFileTypeConfigsPayload = {
  npdbEnabled: boolean;
  npdbAdverseActionsFound: FileLogicFieldType;
  sanctionsEnabled: boolean;
  sanctionsMatchFound: FileLogicFieldType;
  licensesEnabled: boolean;
  expiredLicensesFound: FileLogicFieldType;
  missingLicenses: FileLogicFieldType;
  cleanThreshold: number;
  [key: string]: boolean | number | FileLogicFieldType;
};

export type AccessPermissionsType = {
  orgEntities: boolean;
  providers: boolean;
  credentialing: boolean;
  licensing: boolean;
  monitoring: boolean;
  contracting: boolean;
  facilities: boolean;
  committee: boolean;
  facilityCredentialing: boolean;
  supportingDocs: boolean;
  providerUploads: boolean;
  rosterOs: boolean;
  tasksDashboard: boolean;
  flags: boolean;
  payorEnrollment: boolean;
  peDashboard: boolean;
};

export type DataPropagationConfigurationType = {
  system: boolean;
  user: boolean;
};

export const providerNextCredDateLabels: Record<
  keyof ProviderNextCredDatePreference,
  string
> = {
  exactDate: 'Exact date',
  dayBefore: 'A day before',
  endOfMonth: 'End of the month',
};

export const facilityNextCredDateLabels: Record<
  keyof FacilityNextCredDatePreference,
  string
> = {
  exactDate: 'Exact date',
  dayBefore: 'A day before',
  endOfMonth: 'End of the month',
};

export type ProviderNextCredDatePreference = {
  exactDate: 'Exact date';
  dayBefore: 'A day before';
  endOfMonth: 'End of the month';
};

export type FacilityNextCredDatePreference = {
  exactDate: 'Exact date';
  dayBefore: 'A day before';
  endOfMonth: 'End of the month';
};

export type SLAConfigsType = {
  slaDaysPerFile?: number;
  slaFilesPerAnalyst?: number;
  numOutreachRequired?: number;
  numWorkingDaysBtwOutreach?: number;
  recredentialingMonths?: number;
  facilityRecredentialingMonths?: number;
  slaDaysForRecredReminder?: number;
  monitoringWithoutCredentialing?: boolean;
  notifyOnProviderDataUpdate?: boolean;
  providerNextCredDatePreference?: keyof ProviderNextCredDatePreference;
  facilityNextCredDatePreference?: keyof FacilityNextCredDatePreference;
};

export type NotificationItemConfig = {
  email?: boolean;
  inApp?: boolean;
  type?: (typeof NOTIFICATION_TYPE)[keyof typeof NOTIFICATION_TYPE];
  frequency?: number;
  templateId?: string;
  configType?: (typeof CONFIG_TYPES)[keyof typeof CONFIG_TYPES];
};

export type NotificationFrequencyItemConfig = {
  inApp?: number;
  email?: number;
};

export type NotificationConfig = {
  [NOTIFICATION_EVENT.PAYOR_ENROLLMENT_WORKFLOW_CREATED]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.PAYOR_ENROLLMENT_WORKFLOW_ASSIGNMENT]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.PAYOR_ENROLLMENT_WORKFLOW_STATUS]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.PAYOR_ENROLLMENT_WORKFLOW_EFFECTIVE]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.PAYOR_ENROLLMENT_WORKFLOW_REJECTED]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_ASSIGNMENT]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_COMPLETION]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_CANCELLED]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_REASSIGNMENT]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_NOTE_ADDED]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.TASK_ATTACHMENT_UPLOADED]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.EXPIRY_CAQH]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.EXPIRY_LICENSE]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.EXPIRY_DEA_CDS_LICENSE]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.EXPIRY_BOARD_CERTIFICATION]?: NotificationItemConfig;
  [NOTIFICATION_EVENT.UPCOMING_RECREDENTIALING]?: NotificationItemConfig;
};
export interface INotificationConfigItem {
  section?: (typeof NOTIFICATION_SECTIONS)[keyof typeof NOTIFICATION_SECTIONS];
  id: keyof NotificationConfig;
  label: string;
}

export type StatusChangeEmailConfig = {
  sendEmail?: boolean;
  templateId?: string;
  type?: (typeof NOTIFICATION_TYPE)[keyof typeof NOTIFICATION_TYPE];
  frequency?: number;
};

export type EmailConfigsType = {
  autoSend?: boolean;
  enableExpiryReminders?: boolean;
  credInitiated?: StatusChangeEmailConfig;
  recredInitiated?: StatusChangeEmailConfig;
  credApproved: StatusChangeEmailConfig;
  credDenied?: StatusChangeEmailConfig;
  credCanceled: StatusChangeEmailConfig;
  psvCompleteByCertifyos: StatusChangeEmailConfig;
  licensingWorkflowAssigned: StatusChangeEmailConfig;
  caqhExpiry: StatusChangeEmailConfig;
  stateLicenseExpiry: StatusChangeEmailConfig;
  deaLicenseExpiry: StatusChangeEmailConfig;
  boardCertExpiry: StatusChangeEmailConfig;
  upcomingRecred: StatusChangeEmailConfig;
  peStatusMoveToEffective: StatusChangeEmailConfig;
  peStatusMoveToRejected: StatusChangeEmailConfig;
};

export type FileTypeConfigOverridesType = {
  manual?: boolean;
  nonCleanFlagTypesFlagTypes?: string[];
  autoCalculateFileType?: boolean;
};

export enum OrganizationStatus {
  active = 'active',
  inactive = 'inactive',
}

export type OrganizationPayload = {
  name: string;
  type: string;
  website: string;
  npdbEnrollmentEnabled?: boolean;
  caqhEnabled?: boolean;
  slaConfigs?: SLAConfigsType;
  accessPermissions?: AccessPermissionsType;
  emailConfigs?: Partial<EmailConfigsType>;
  notificationConfig?: Partial<NotificationConfig>;
  status: OrganizationStatus;
  referenceLinksConfig?: ReferenceLinksConfig;
  psvRequirementsLinkUrl?: string;
  psvConfigs?: PsvConfigs;
  dataPropagationConfiguration?: DataPropagationConfigurationType;
};

export interface OrganizationSLAConfigsDto {
  slaDaysPerFile: number;
  slaFilesPerAnalyst: number;
  numOutreachRequired: number;
  numWorkingDaysBtwOutreach: number;
  recredentialingMonths: number;
  facilityRecredentialingMonths: number;
  monitoringWithoutCredentialing: boolean;
}

export interface OrganizationAccessPermissionsDto {
  orgEntities: boolean;
  providers: boolean;
  credentialing: boolean;
  licensing: boolean;
  monitoring: boolean;
  contracting: boolean;
  facilities: boolean;
  committee: boolean;
  facilityCredentialing: boolean;
  supportingDocs: boolean;
  providerUploads: boolean;
  rosterOs: boolean;
  tasksDashboard: boolean;
  flags: boolean;
  payorEnrollment: boolean;
  peDashboard: boolean;
}
export interface DataPropagationConfigurationDTO {
  system: boolean;
  user: boolean;
}

export type OrganizationStatusChangeEmailConfig = {
  autoSend: boolean;
};

export interface CreateOrgApiPayload {
  name: string;
  type: string;
  website: string;
  contactPhone?: string;
  contactEmail?: string;
  isSuperOrg?: boolean;
  parentOrganizationId?: string;
  slaConfigs: OrganizationSLAConfigsDto;
  accessPermissions: OrganizationAccessPermissionsDto;
  emailConfigs: OrganizationStatusChangeEmailConfig;
  userIds: string[];
}

export enum SecretType {
  NPDB = 'NPDB',
  CAQH = 'CAQH',
}

export const SecretTypeConfigKeys: any = {
  [SecretType.NPDB]: 'npdbEnrollmentEnabled',
  [SecretType.CAQH]: 'caqhEnabled',
};

export type AirtablePayload = {
  airtableUrl: string;
  airtableTitle: string;
  dataSyncEnabled?: boolean;
};

export enum SurveyType {
  FACILITY = 'facility',
  PROVIDER = 'provider',
}

export type SurveyTypeKey = SurveyType.FACILITY | SurveyType.PROVIDER;

export type SurveyQuestion = {
  is_required: boolean;
  rtxt: string;
  id: number;
  selectedField: string;
  [key: string]: any;
};

export type SurveyQuestionPayload = {
  label: string;
  id: string;
  field: string;
  isDocument?: boolean;
  subCollection?: string;
};

export type SurveyConfigType = {
  surveyId: string;
  surveyType: SurveyTypeKey;
  externalIdLabel?: string;
  questions: SurveyQuestionPayload[];
};

export type AssignUserToOrganizationsPayload = {
  organizationIds: string[];
};

export type AssignMembersPayload = {
  organizationId: string;
  userIds: string[];
};

export type organizationMembershipsResponse = {
  data: OrganizationMembership[];
  count: number;
};

export type membershipOrganizationsResponse = {
  orgs: MembershipOrganization[];
  count: number;
};

export interface SearchPaginationQueryParams extends PaginationQueryParams {
  q?: string;
  organizationId: string;
}
