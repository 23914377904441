import {
  FacilitiesCredWorkflowsApiResponse,
  FacilityCredWorkflowDetailsApiResponse,
  FacilityWorkflowDetails,
} from '#/types/facility-credentialing-workflows.types';
import {
  CREDENTIALING_TRANSFORMER_SOURCES,
  transformStepsForCredTimeline,
} from './credentialing.transformer';
import { transformFetchFacilityByIdResponse } from './facility.transformer';

export const transformFetchFacilityCredWorkflowDetailsResponse = (
  data: FacilityCredWorkflowDetailsApiResponse,
): FacilityWorkflowDetails | any => {
  const source =
    CREDENTIALING_TRANSFORMER_SOURCES.FACILITY_CREDENTIALING_TRANSFORMER;
  const transformedFacilityDetails = transformFetchFacilityByIdResponse(
    data.facilityDetails,
    source,
  );
  const transformedSteps = transformStepsForCredTimeline(data.steps, source);

  const response: FacilityWorkflowDetails = {
    ...data,
    facilityDetails: transformedFacilityDetails,
    steps: transformedSteps,
  };

  return response;
};

export const transformFetchFacilityCredWorkflowsResponse = (
  response: FacilitiesCredWorkflowsApiResponse,
): FacilitiesCredWorkflowsApiResponse | any => {
  const workflows = response.workflows
    ? response.workflows.map((item) => {
        return {
          ...item,
          onStep: item.onStep?.title,
        };
      })
    : [];

  return {
    ...response,
    workflows,
  };
};
